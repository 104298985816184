<template>
    <div class="flex h-screen flex-col items-center justify-center">
        <h1 class="text-xl">
            {{ title }}
        </h1>
        <div class="text-base">
            {{ description }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import useHttpStatus from '@aspect/shared/composables/use-http-status.ts';

    defineOptions({
        layout: '',
    });

    const props = defineProps<{
        status: 503 | 500 | 404 | 403;
    }>();

    const { getTitle, getDescription } = useHttpStatus();

    const title = computed(() => getTitle(props.status));
    const description = computed(() => getDescription(props.status));
</script>
